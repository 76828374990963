.menubar .menulink {
  background-color: #156a27;
  color: #fff;
  font-weight: bold;
  border: 1px solid;
  text-align: center;
  padding: 0.6em 2px;
}

.menubar a {
  text-decoration: none;
  color: #fff;
}
.menubar a:hover {
  text-decoration: none;
  color: #000;
  transition: 0.7s;
}

.logomenu {
  padding: 2rem;
}

marquee {
  background-color: #ce1717;
  color: #fff;
  padding: 1rem 0px;
}

.dayResult {
  background-color: #272442;
  padding-top: 5px;
  padding-bottom: 2px;
  text-align: center;
  color: #fff;
}

.result {
  background-color: #000;
  padding: 5px;
  border: double 5px #1ad03f;
  border-radius: 20px;
  color: #fff;
}

/* ========day component====== */
.heading {
  /* margin: 0 17px; */
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 #bfbfbf;
  padding: 1rem;
  background: #000;
  color: #fff;
}

.card {
  border: 0px !important;
  margin: 8px 1px !important;
  padding: 1rem;
  /* border: 1px solid #000; */
  text-align: center;
  background-color: #f5eafd !important;
  box-shadow: 0 40px 20px -40px #ddd;
  border: 5px solid #ddd !important;
}

.card h6 {
  color: #9b0606;
}

.card span {
  font-weight: bold;
}

.card .resultGame span:nth-child(2) {
  color: blue;
}

/* =======antd table======= */
.ant-table-thead tr th {
  text-wrap: nowrap;
  background-color: red !important;
  border-radius: 0px !important;
  /* padding: 0.5em !important; */
  margin: 1px !important;
  border: 1px solid #fff;
  color: #fff !important;
  border-collapse: separate !important;
  text-align: center !important;
}

.ant-table-tbody td:nth-child(1) {
  text-wrap: nowrap;
  background-color: red !important;
  border-radius: 0px !important;
  /* padding: 0px 3rem !important; */
  margin: 0px !important;
  border: 1px solid #fff;
  color: #fff !important;
  border-collapse: separate !important;
  text-align: center;
}

.ant-table-cell {
  border-collapse: separate !important;
  border: 1px solid #000 !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 2px !important;
}

.ant-table-wrapper .ant-table-cell {
  /* position: relative; */
  padding: 5px 1rem !important;
  /* overflow-wrap: break-word; */
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent !important;
}

.tableStyle {
  border: 1px solid;
}

#go-button {
  color: #fff;
  background-color: #ff0000;
  border-color: #930404;
  cursor: pointer;
}

#go-button:hover {
  background-color: #930404;
}

#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  background-color: #930404;
  border: 1px solid;
  font-weight: bold !important;
}

/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #eeb7b7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ff0000;
  border: 1px solid;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #eeb7b7;
}

/* ========acordian====== */
.accordion-button {
  background: #333 !important;
  margin: 0px !important;
  border: 2px solid #fff !important;
  color: #fff !important;
}

.accordion-body {
  background: #333 !important;
  margin: 0px !important;
  color: #ffffff !important;
}

.monthTable,
.allFooterPage {
  background-color: #fff;
  color: #000;
  font-style: bold;
  border-width: 3px;
  border-color: #0008ff;
  border-style: outset;
  margin: 3px;
  padding: 10px;
  border-radius: 10px;
  /* text-align: center; */
}

.allFooterPage h3 {
  color: red;
  text-align: center;
}

.monthTable p {
  color: #707070;
  font-weight: bold;
}

.footerContent .content {
  padding: 1.5%;
  font-size: 16px;
  color: #fff;
  background: #212121;
  text-transform: capitalize;
  margin: 5px 2px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 3px 0 #bfbfbf;
  -moz-box-shadow: 0 1px 3px 0 #bfbfbf;
  box-shadow: 0 1px 3px 0 #bfbfbf;
  text-align: center;
}

.footerContent .content h3 {
  color: #fff700;
}

.button-group .btn {
  margin: 3px 3px;
  color: #fff;
  /* font-weight: bold; */
  /* padding:0px 1rem; */
}

.button-group {
  text-align: center;
  font-weight: 700;
  margin: 0px 0px;
  background-color: #2c2c2c;
  border: 3px solid #2c2c2c;
  padding: 1.5rem 0rem;
}

.copyright {
  background-color: #000;
  color: #fff;
  padding: 1.5rem 0rem;
}

/* =========drop down===== */
.css-b62m3t-container,
#go-botton {
  border: 1px solid #ff0000;
}

.css-zkyqxq-control,
.css-1s7v18u-control {
  background-color: #930404 !important;
  color: #fff !important;
}

.css-j4o025-singleValue,
.selection-container ::placeholder {
  color: #fff !important;
}

/* =============old=============== */
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.monthYrTbl {
  text-transform: uppercase;
}

.toast-message {
  background: red !important;
  color: #fff !important;
  border: 5px solid #940505;
}

.Toastify__toast-body,
.toast-message button {
  color: #fff !important;
}

.Toastify__progress {
  background-color: green !important;
}

/* blink text */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
}

/* =====text color animation====== */
h2 {
  font-size: 16px;
  font-weight: bold;
  /* font-family: serif; */
  color: transparent;
  /* text-align: center; */
  animation: effect 1s linear infinite;
  text-transform: uppercase;
}

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}


/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  color:  #fff;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #ff0095;
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  /* background-color: #3933d0; */
  /* Light cream background */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin: 20px;
}

/* Heading styles */
.advertisementSection h3 {
  color: #d35400;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #2c3e50;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #0d1eb6;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}
